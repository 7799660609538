<template>
  <section>
    <b-card>
      <b-row>
        <b-col
          sm="12"
          class="text-right"
        >
          <action-button
            variant="primary"
            :disabled="isFetching"
            @click="goToEditSurvey"
          >
            <span class="text-nowrap">Edit Survey</span>
          </action-button>
          <b-button
            v-if="viewMode === 'champion'"
            variant="primary"
            class="ml-2"
            :disabled="isFetching"
            @click="viewSurveyReport"
          >
            <span class="text-nowrap">View Report</span>
          </b-button>
          <span
            v-if="viewMode === 'champion' && !canSendSurveyNow"
            v-b-tooltip.hover.html.bottom="`<div class='text-left'>Send now option activates when:<br/>- Survey status is enabled<br/>- Survey is scheduled for a key date, a participant action, or a custom date (absolute date)</div>`"
          >
            <b-button
              variant="primary"
              class="ml-2"
              :disabled="true"
            >
              <span class="text-nowrap">Send Now</span>
            </b-button>
          </span>
          <action-button
            v-else-if="viewMode === 'champion'"
            variant="primary"
            class="ml-2"
            :disabled="!canSendSurveyNow || isSending"
            @click="sendSurveyNow"
          >
            <span class="text-nowrap">Send Now</span>
          </action-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <question-skeleton-loader v-if="isFetching" />
          <b-row v-else>
            <b-col sm="12">
              <h5 class="mt-2">
                Survey - Details
              </h5>
            </b-col>
          </b-row>
          <b-row v-if="survey">
            <b-col sm="6">
              <h5>Title:</h5>
              {{ survey.translations[0].title }}

              <h5 class="mt-1">
                Role:
              </h5>
              <span
                v-for="(role, index) in survey.roles"
                :key="index"
              >
                {{ viewMode == 'champion' ? roleAlias(role.id, defaultProgram) : userRolesDisplay[role.id] }}<span v-if="index < survey.roles.length - 1">,</span>
              </span>
            </b-col>
            <b-col sm="6">
              <h5>Description:</h5>
              {{ survey.translations[0].description }}
              <h5 class="mt-1">
                When to send:
              </h5>
              <div
                v-for="action in survey.scheduled_actions"
                :key="action.id"
              >
                <span>
                  {{ getAvailability(action) }}
                </span>
              </div>
            </b-col>
          </b-row>

          <b-card
            v-for="(question, index) in surveyQuestions"
            :key="question.id"
            no-body
            class="mt-2 mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
              :style="{ 'background-color': question.background }"
            >
              <b-col sm="12">
                <span>{{ index + 1 }}.
                  {{ question.translations[0].question_text }}</span>
              </b-col>
            </b-card-header>

            <b-card-body :class="{'overflow-scroll': question.type_id === questionTypes.SCALE}">
              <div v-if="question.type_id === questionTypes.TEXT">
                <label class="mt-1">Info text</label>
                <b-form-group label-for="prefilled-answer">
                  <b-form-textarea
                    :value="question.translations[0].prefilled_answer"
                    disabled
                    placeholder="Prefilled answer"
                    maxlength="255"
                    rows="3"
                  />
                </b-form-group>
              </div>
              <div v-if="question.type_id === questionTypes.CHOICE">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <b-form-radio
                            disabled
                            name="question-options"
                            class="mt-1"
                          />
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            v-if="question.choices[i].translations"
                            :value="
                              question.choices[i].translations[0].choice_text
                            "
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.SCALE">
                <b-row
                  v-for="indexRow in question.statements.length + 1"
                  :key="indexRow"
                  class="mt-1 flex-nowrap"
                >
                  <b-col
                    v-for="indexCol in question.choices.length + 2"
                    :key="indexCol"
                    class="col-sm-3"
                  >
                    <div
                      v-if="
                        indexRow === 1 &&
                          indexCol === question.choices.length + 2
                      "
                      class="minimal-width"
                    />

                    <div
                      v-if="
                        indexRow === 1 &&
                          indexCol > 1 &&
                          indexCol < question.choices.length + 2
                      "
                    >
                      <b-input-group :label-for="'option' + (indexCol - 1)">
                        <b-form-textarea
                          v-if="question.choices[indexCol - 2].translations"
                          disabled
                          :value="
                            question.choices[indexCol - 2].translations[0]
                              .choice_text
                          "
                          type="text"
                          class="min-statement-width"
                          :placeholder="'Option ' + (indexCol - 1)"
                          style="overflow: hidden"
                          size="sm"
                          no-resize
                        />
                      </b-input-group>
                    </div>
                    <div v-else-if="indexRow > 1 && indexCol === 1">
                      <b-input-group :label-for="'statement' + (indexRow - 1)">
                        <b-form-textarea
                          v-if="
                            question.statements[indexRow - 2].translations[0]
                          "
                          :value="
                            question.statements[indexRow - 2].translations[0]
                              .question_text
                          "
                          disabled
                          class="min-statement-width"
                          type="text"
                          :placeholder="`Statement ${indexRow - 1}`"
                          style="overflow: hidden"
                          size="sm"
                          no-resize
                        />
                      </b-input-group>
                    </div>
                    <div
                      v-else-if="
                        indexRow > 1 &&
                          indexCol > 1 &&
                          indexCol < question.choices.length + 2
                      "
                    >
                      <b-form-radio
                        disabled
                        name="option-radio"
                        class="ml-3"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BButton,
  BCardBody,
  BFormTextarea,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BInputGroup,
  VBTooltip
} from "bootstrap-vue";
import { mapGetters } from "vuex";

import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { questionTypes } from "@models/questionTypes";
import formsService from "@/services/formsService";
import QuestionSkeletonLoader from "../../questions/QuestionSkeletonLoader.vue";
import { userRolesDisplay } from "@models/userRoles";
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import { surveyService } from "@/services";
import { scheduledActionStatus } from '@/models/scheduledActionStatus';
import { actions } from "@/models";
import moment from "moment";
import { roleAlias } from "@/@aom-core/utils/utils";
import ActionButton from "@/views/components/common/ActionButton.vue";


export default {
  name: "SurveyForm",
  components: {
    ActionButton,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BButton,
    BCardHeader,
    BFormTextarea,
    BFormGroup,
    BFormRadio,
    BFormInput,
    QuestionSkeletonLoader,
    BInputGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    viewMode: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      isFetching: false,
      isSending: false,
      surveyQuestions: [],
      survey: undefined,
      surveyId: this.$route.params.survey,
      questionTypes,
      userRolesDisplay,
      canSendSurveyNow: false
    };
  },
  computed: {
    ...mapGetters("programs", ['defaultProgram']),
  },
  watch: {
    surveyQuestions: {
      handler(n) {
        const updateSort = n.map((q, i) => ({ ...q, question_order: i }));
        this.survey = {
          ...this.survey,
          questions: updateSort,
        };
      },
      deep: true,
    },
  },
  created() {
    this.fetchSurvey();
  },
  methods: {
    async sendSurveyNow() {
      const h = this.$createElement;
      const messageVNode = h('div', {}, [
        h('p', 'Are you sure you want to send this survey now?'),        
        h('p', this.survey.translations[0].title),        
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: 'Send Survey Now',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const programId = this.$route.params.id;
              const result = await surveyService.runSurveyNow(programId, this.surveyId);
              if (result) {
                this.$toast(makeSuccessToast('Survey was sent successfully.'));
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
    async fetchSurvey() {
      try {
        this.isFetching = true;
        let response = null;
        if (this.viewMode === "champion") {
          response = await formsService.getProgramSurvey(this.$route.params.id, this.surveyId);
          const { data } = response;
          this.surveyQuestions = data.questions;
          this.survey = data;
          const scheduledAction = this.survey.scheduled_actions[0];
          this.canSendSurveyNow = scheduledAction.status_id === scheduledActionStatus.ENABLED
            && scheduledAction.action_id === actions.SHOW
            && (
              scheduledAction.actionable_event_id !== null 
              || 
              scheduledAction.absolute_date && moment().subtract(1, 'days') <= moment(scheduledAction.absolute_date) 
              && moment().add(1, 'days') >= moment(scheduledAction.absolute_date)
            );
        } else {
          response = await formsService.getSurvey(this.surveyId);
          const { data } = response;
          this.surveyQuestions = data.questions;
          this.survey = data;
        }
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isFetching = false;
      }
    },

    goToEditSurvey() {
      // Champion portal
      if (this.viewMode === "champion") {
        this.$router.push({
          name: "champion-program-survey-edit",
        });
      } else {
        // Admin portal
        if (this.$route.name === 'admin-program-survey') {
          this.$router.push({
            name: "admin-program-survey-edit",
          });
        } else {
          this.$router.push({
            name: "admin-survey-edit",
          });
        }
      }
    },

    viewSurveyReport() {
      this.$router.push({
        name: "champion-program-survey-metric",
        params: {
          id: this.$route.params.id,
          survey: this.survey.id
        }
      });
    },

    getAvailability(action) {
      return useGetAvailability(action);
    }
  },
  setup() {
    return {
      roleAlias
    };
  }
};
</script>
<style lang="scss">
.card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
.overflow-scroll {
  overflow: auto;
}
</style>
