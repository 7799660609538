<template>
  <survey-form-preview view-mode="champion" />
</template>

<script>
import SurveyFormPreview from "@/views/components/forms/surveys/SurveysFormPreview.vue";
export default {
  components: {
    SurveyFormPreview
  }
};
</script>